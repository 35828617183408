
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Huge Collection',
        desc: 'Oficia dese runt mollit anim id est labo met, consectetur adipis'
    },
    {
        id: 2,
        img: img2,
        title: 'High Quality',
        desc: 'Oficia dese runt mollit anim id est labo met, consectetur adipis'
    },
    {
        id: 3,
        img: img3,
        title: 'Top Resource',
        desc: 'Oficia dese runt mollit anim id est labo met, consectetur adipis'
    },
    {
        id: 4,
        img: img4,
        title: 'Big Community',
        desc: 'Oficia dese runt mollit anim id est labo met, consectetur adipis'
    },

]

export default dataBox;